import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type { BookingStatus, IBookingData } from "~/types/booking/booking";
import { StatusCodes } from "http-status-codes/build/cjs/status-codes";
import { ROUTES } from "~/constants/routes";
import { BookingStatuses } from "~/constants/booking";

export const useBooking = () => {
    const { get, post } = useApi();
    const { tryAction } = useSafe();
    const config = useRuntimeConfig();

    /**
    * Возвращает идентификаторы активных бронирований.
    * @returns список идентификаторов активных бронирований.
    */
    const getActiveBookings = async () : Promise<Result<number[]>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/active`;
            return await get<number[]>(url);
        });
    };

    /**
    * Функция для получения информации о бронировании
    * @param bookingId идентификатор бронирования
    * @returns информации о бронировании
    */
    const getBookingData = async (bookingId: number) : Promise<Result<IBookingData>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}`;
            return await get<IBookingData>(url);
        });
    };

    const goToBooking = (bookingId: number, bookingStatus: BookingStatus) => {
        const link = getButtonLink(bookingStatus);
        if (!link) {
            throw createError({
                fatal: true,
                statusCode: StatusCodes.NOT_FOUND,
            });
        }

        navigateTo({
            path: ROUTES.booking_id(bookingId),
            query: {
                step: link,
            },
        });
    }

    /**
    * Определяет, куда ведет кнопка "Продолжить"
    * @param status статус бронирования
    * @returns ссылка для кнопки
    */
    const getButtonLink = (status: BookingStatus) => {
        switch (status) {
        case BookingStatuses.SelectPurchaseConditions:
            return "conditions";
        case BookingStatuses.CheckPersonalData:
            return "personal";
        case BookingStatuses.Offer:
        case BookingStatuses.Payment: // оплата должна проходить через повторную оферту
            return "offer";
        default:
            return null;
        }
    };

    /**
     * Отменяет бронирование клиента
     * @param bookingId идентификатор бронирования
     */
    const cancelBooking = async (bookingId: number) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}/cancel`;
            return await post(url, {});
        });
    };

    return {
        getActiveBookings,
        getBookingData,
        goToBooking,
        cancelBooking
    };
};
