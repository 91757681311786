export const ROUTES = {
    index: '/',
    users: '/users',
    user: (link: string | undefined) => {
        return `/users/${link}`;
    },
    settlement: '/settlement',
    settlementClient: '/settlement/client',
    settlementAdmin: '/settlement/admin',
    settlement_monitoring: '/settlement/admin/monitoring',
    settlement_acceptances: '/settlement/admin/acceptances',
    acceptance: '/settlement/client/acceptance',
    acceptance_id: (link: string | number | null) => {
        return `/settlement/client/acceptance/${link}`;
    },
    booking: '/booking',
    bookingClient: '/booking/client',
    bookingAdmin: '/booking/admin',
    booking_id: (link: string | number | null) => {
        return `/booking/client/${link}`;
    },
    booking_mortgage: (link: string | number | null) => {
        return `/booking/client/${link}/mortgage`;
    },
    booking_installment: (link: string | number | null) => {
        return `/booking/client/${link}/installment`;
    },
    booking_summary: (link: string | number | null) => {
        return `/booking/client/${link}/summary`;
    },
    mortgage_id: (link: string | number | null) => {
        return `/mortgage/${link}`;
    },
    mortgage_create: (link: string | number | null) => {
        return `/mortgage/create?bookingId=${link}`;
    },
    mortgage_login: (link: string | number | null) => {
        return `/mortgage/login?bookingId=${link}`;
    },
    partners: '/partners',
    partner: (link: string | number | null) => {
        return `/partners/${link}`;
    },
    partner_links: (link: string | number | null) => {
        return `/partners/${link}/links`;
    },
    promocodes: (link: string | number | null) => {
        return `/partners/${link}/promocodes`;
    },
    certificates: (link: string | number | null) => {
        return `/partners/${link}/certificates`;
    },
    transactions: '/transactions',
    login: '/login',
    admin: '/admin',
    email_confirmed: '/email/confirmed',
    categories: '/categories',
    error: "/error"
};
