import type { Result } from "@frontend/ui-kit/src/types/result";
import type { IStepsRealty, IRealtyFullInfo } from "~/layers/booking/types/realty";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import priceHelper from '@frontend/ui-kit/src/utils/priceHelper';
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';

export const useBookingStepsRealty = () => {
    const { t } = useI18n();
    const { get } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();
    /**
    * Функция для получения информации об объекте
    * @param bookingId идентификатор бронирования
    * @returns объект полей с информацией
    */ 
    const getRealtyData = async (bookingId: number) : Promise<Result<IStepsRealty>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}/short`;
            return await get<IStepsRealty>(url);
        });
    };

    /**
    * Возвращает строку заголовка для объекта
    * @param realty объект (realty)
    * @returns Заголовок объекта в виде строки
    */
    const createTitle = (realty: IStepsRealty) => {
        if (!realty) {
            return '';
        }

        const realtyTypeName = realty.realtyTypeName;
        const realtySquare = realty.square ? `${realty.square} ${t('forms.shared.dimensions.meters2')}` : '';

        return `${realtyTypeName} ${realtySquare}`;
    }
    
    /**
    * Возвращает строку общей информации об объекте
    * @param realty объект (realty)
    * @returns Информация об объекте в виде строки
    */
    const createMainlInfo = (realty: IStepsRealty) => {
        if (!realty) {
            return '';
        }

        const project = realty.project ? `${realty.project} &bull;` : '';

        const address = realty.address ? realty.address : '';
        const realtyName = realty.realtyTypeName ? realty.realtyTypeName.toLowerCase() : '';
        const floor = realty.floorNumber 
            ? `${t('booking.common.info_titles.floor').toLowerCase()} ${realty.floorNumber}` 
            : '';
        const number = realty.number ? `№${realty.number}` : '';
        const rooms = realty.roomCount ? `${realty.roomCount}-${t('realty.rooms')}` : '';
        const circle = address || floor || number || rooms? '&bull;' : '';

        const fullAddress = address + (realtyName && number || floor || rooms ? ',' : '');
        const fullFloor = floor + (realtyName && number || rooms ? ',' : '');
        const fullRealtyName = `${realtyName} ${number}${rooms ? ',' : ''}`;

        const fullLocation = `${fullAddress} ${fullFloor} ${fullRealtyName} ${rooms} ${circle}`;
        const isTypeFinishName = realty.isTypeFinish ? t('booking.common.info_titles.withFinishing') : t('booking.common.info_titles.withoutFinishing');
    
        return `${project} ${fullLocation} ${isTypeFinishName}`;
    }
    
    /**
    * Возвращает массив подробной информации об объекте
    * @param realty объект (realty)
    * @returns Массив информации об объекте: заголовок-значение
    */
    const createFullInfo  = (realty: IStepsRealty) :IRealtyFullInfo[] => {
        if (!realty) {
            return [];
        }

        const realtyName = realty.realtyTypeName ? realty.realtyTypeName.toLowerCase() : '';
        const number = realty.number ? `№${realty.number}` : '';
        const address = realty.address ? (realtyName && number ? realty.address + ',' : realty.address) : '';
        const project = realty.project ? `${realty.project}${address || realtyName && number ? ',' : ''}` : '';
        const fullAddress = `${project} ${address} ${realtyName} ${number}`;
        const floorNumber = realty.floorNumber ? realty.floorNumber : '';
        const floorsCount = realty.floorsCount ? `/${realty.floorsCount}` : '';
        const floors = `${floorNumber}${floorsCount}`;
        const isTypeFinishName = realty.isTypeFinish ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no');

        const info: IRealtyFullInfo[] = [
            {
                title: `${t('booking.common.info_titles.location')}: `,
                text: fullAddress,
            },
        ];

        if (realty.roomCount) {
            info.push({
                title: `${t('booking.common.info_titles.rooms')}: `,
                text: realty.roomCount.toString(),
            });
        }

        info.push({
            title: `${t('booking.common.info_titles.floor')}: `,
            text: floors,
        });
        info.push({
            title: `${t('booking.common.info_titles.finishing')}: `,
            text: isTypeFinishName,
        });

        return info;
    }
    
    /**
    * Возвращает массив информации об объекте в контентной части аккордеона
    * @param realty объект (realty)
    * @returns Массив информации об объекте: заголовок-значение
    */
    const createContentInfo  = (realty: IStepsRealty) :IRealtyFullInfo[] => {
        if (!realty) {
            return [];
        }

        const bookingEnd = dateTimeHelper.toDate(realty.bookingEnd);
        return [
            {
                title: `${t('booking.common.info_titles.realty_price')}: `,
                text: realty.realtyPrice ? priceHelper.toStringWithRuble(realty.realtyPrice) : '—',
            },
            {
                title: `${t('booking.common.info_titles.purchase_condition')}: `,
                text: realty.purchaseCondition ? realty.purchaseCondition : '—',
            },
            {
                title: `${t('booking.common.info_titles.booking_price')}: `,
                text: realty.bookingPrice ? priceHelper.toStringWithRuble(realty.bookingPrice) : '—',
            },
            {
                title: `${t('booking.common.info_titles.booking_period')}: `,
                text: realty.bookingEnd ? `${t('forms.shared.prepositions.until')} ${dateTimeHelper.toDateString(bookingEnd)}` : '—',
            },
        ];
    };
    /** Поля для объекта бронирования по умолчанию */
    const defaultRealty:IStepsRealty = {
        realtyId: 0,
        realtyTypeName: '',
        address: '',
        square: 0,
        schemeUrl: '',
        number: '',
        project: '',
        roomCount: 0,
        floorNumber: 0,
        floorsCount: 0,
        isTypeFinish: false,
        realtyPrice: 0,
        bookingPrice: 0,
        purchaseCondition: '',
        bookingEnd: '',
        bookingSessionEnd: '',
    }

    return {
        getRealtyData,
        createTitle,
        createMainlInfo,
        createFullInfo,
        createContentInfo,
        defaultRealty,
    };
};