<script setup lang="ts">
import { StatusCodes } from 'http-status-codes';

interface Props {
    statusCode?: number | undefined;
}

withDefaults(defineProps<Props>(), {
    statusCode: undefined,
});

const config = useRuntimeConfig();

const handleError = async () : Promise<void> => {
    await clearError();
    await navigateTo(config.public.siteLoginUrl, { external: true });
}
</script>

<template>
    <NuxtLayout>
        <div class="content">
            <div class="error-text">{{ statusCode }}</div>
            <div class="text">
                <div v-if="statusCode == StatusCodes.NOT_FOUND" id="error">
                    {{ $t('error.pageNotFound') }}
                </div>
                <div v-else-if="statusCode == StatusCodes.FORBIDDEN" id="error">
                    {{ $t('error.accessDenied') }}
                </div>
                <div v-else id="error">
                    {{ $t('error.title') }} {{ statusCode }}
                </div>
            </div>
            <a 
                id="toCabinet" 
                class="actions button"
                @click.prevent="handleError()"
            >{{ $t('error.mainPage') }}</a>
        </div>
    </NuxtLayout>
</template>

<style scoped lang="scss">
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Padding-pa-10, 40px);
    flex: 1 0 0;
    align-self: stretch;

    @include responsive( 'mobile' ) {
        padding: 0;
    }

    .error-text {
        color: var(--Color-Brand-500, $error-color);
        text-align: center;

        font-size: 96px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 96px */
        letter-spacing: -0.96px;
        text-transform: uppercase;

        @include responsive('mobile') {
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 105%; /* 63px */
            letter-spacing: -0.6px;
            text-transform: uppercase;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        color: var(--Color-Zink-950, $main-active-color);
        text-align: center;

        width: 384px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 30px */
        letter-spacing: -0.24px;
        text-transform: uppercase;
        
        @include responsive('mobile') {
            width: inherit;
        }
    }

    .actions {
        display: flex;
        width: 384px;
        padding: var(--Padding-pa-4, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--Border-radius-rounded-xmd-2, 10px);

        border-radius: var(--Border-radius-rounded-lg, 12px);
        background: var(--Color-Zink-950, $main-active-color);

        @include responsive( 'mobile' ) { 
            width: 100%;
        }

        &.button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            color: var(--Color-Zink-50, $main-card-color);
            text-align: center;

            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }
    }
}
</style>