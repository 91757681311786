<script setup lang="ts">
import Error from "~/components/shared/Common/error/Error.vue";
const { t } = useI18n();

useHead({ title: t("routes.error") });
const error = useError();
</script>

<template>
    <Error :statusCode="error?.statusCode" />
</template>