<script setup lang="ts">
interface Props {
    src: string;         // ссылка/путь к изображению
    alt?: string;        // текст для атрибута alt
    styleClass?: string; // класс для управления стилями изображения
}

const props = withDefaults(defineProps<Props>(), {
    alt: '',
    styleClass: '',
});

const imageStyles = ref({});

//для svg работает высота только с flexGrow 
//для контейнера изображения должен быть установлено свойство display: flex
const setImageStyles = (src: string) => {
    const isSvg = src.endsWith('.svg');
    if (isSvg) {
        imageStyles.value = { flexGrow: 1 }; 
    } else {
        imageStyles.value = {};
    }
};

setImageStyles(props.src);

const onImageLoad = (event: Event) => {
    const img = event.target as HTMLImageElement;

    if (!img) {
        return;
    }

    const width = img.naturalWidth;
    const height = img.naturalHeight;

    if (width > height) {
        imageStyles.value = { ...imageStyles.value, width: '100%', height: 'auto' }; 
    } else {
        imageStyles.value = { ...imageStyles.value, width: 'auto', height: '100%' };
    }
};
</script>

<template>
    <img 
        :src="src" 
        alt="scheme"
        :class="['image', styleClass]"
        :style="imageStyles" 
        @load="onImageLoad" 
    />
</template>

<style scoped lang="scss">
.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>
