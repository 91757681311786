export const booking = {
    common: {
        timer: {
            processing:'Бронирование истечет через 20 минут, и процесс нужно будет повторить.',
            over: 'Бронирование истекло',
            title: 'Бронирование',
        },
        info_titles: {
            location: 'Локация',
            floor: 'Этаж',
            finishing: 'Отделка',
            realty_price: 'Стоимость объекта',
            booking_price: 'Стоимость бронирования',
            purchase_condition: 'Условия покупки',
            booking_period: 'Срок бронирования',
            booking_start: 'Дата бронирования',
            booking_end: 'Дата окончания',
            booking_rate: 'Тариф бронирования',
            realty_price_short: 'Стоимость ОН',
            payment_method: 'Способ оплаты',
            rooms: 'Комнат',
            withFinishing: 'С отделкой',
            withoutFinishing: 'Без отделки',
        },
        navigation: {
            objects: "Объекты",
        },
        statuses: {
            client: {
                in_process: 'В процессе',
                booked_until: 'Забронировано до',
                approved: 'Одобрено',
                awaiting_confirmation: 'Ожидает подтверждения',
                expired: 'Не завершено',
                cancelled: 'Договор не подписан',
                refusal: 'Не одобрено',
                deal: 'Договор',
            },
            tooltips: {
                wait: 'Заказ на бронирование принят. Средства на карте заблокированы; списание произойдет после подтверждения бронирования менеджером.',
                refusal: 'Объект недоступен. Деньги вернутся на карту. Поможем с выбором нового.',
            }
        },
    },
    client: {
        purchase_conditions: {
            title: 'Выберите способ покупки',
            tooltip: '1 шаг - Выбор способа покупки',
            text: 'Для вашего удобства мы предлагаем несколько вариантов приобретения недвижимости. Выберите наиболее подходящий для вас способ оплаты:',
            types: {
                mortgage: 'Ипотека от',
                mortgage_short: 'Ипотека',
                installment: 'Рассрочка от',
                installment_short: 'Рассрочка',
                immediate: 'Полная оплата'
            },
            items: {
                initialPayment: 'Первый взнос',
                cost: 'Стоимость',
                creditPeriod: 'Срок',
                rate: 'Ставка',
            },
            mortgage: {
                types: {
                    arctic: 'Арктическая ипотека',
                    family: 'Семейная ипотека',
                    far_east: 'Дальневосточная',
                    government_support: 'Государственная поддержка',
                    it: 'Ипотека для ИТ-специалистов',
                    military: 'Военная ипотека',
                    standart: 'Стандартная ипотека',
                    village: 'Сельская ипотека'
                },
                filter: {
                    select_program: 'Выберите программу',
                    initial_payment_percent: 'Первоначальный взнос',
                    period_in_years: 'Срок'
                },
                list: {
                    card: {
                        initial_payment_percent: 'Первый взнос',
                        rate: 'Ставка',
                        period: 'Срок ',
                        credit_amount: 'Сумма'
                    },
                    empty: 'Список ипотечных программ пока пуст',
                    emptyDescription: 'Попробуйте использовать другие фильтры',
                },
                validation: {
                    select_program: 'Выберите программу',
                }
            },
        },
        personal_data: {
            title: 'Введите личные данные',
            tooltip: '2 шаг - Ввод личных данных',
            text: 'Для оформления бронирования нужны ваши данные и почта для отправки подтверждения. Убедитесь, что указали данные будущего владельца квартиры.',
            form: {
                emailInfo: 'На эту почту придет подтверждение бронирования',
            },
        },
        offer: {
            title: 'Договор оферты',
            tooltip: '3 шаг - Принятие договора оферты',
        },
        payment: {
            title: 'Оплата',
            tooltip: '4 шаг - Оплата',
        },
        result: {
            success: {
                title: 'Оплата прошла успешно',
                text: 'Чтобы найти ваше бронирование, перейдите в личный кабинет и выберите вкладку «Бронирования».',
                button: 'Перейти в личный кабинет',
            },
            fail: {
                title: 'Ошибка оплаты',
                text: 'К сожалению, оплата не удалась. Попробуйте другой способ оплаты или свяжитесь с поддержкой.',
                button: 'Повторить оплату',
            },
            undefined: {
                text: 'Что-то пошло не так',
                button: 'Перейти в личный кабинет',
            }
        },
        bookings: {
            title: 'Ваши Бронирования',
            list: {
                empty: 'Список объектов пока пуст',
                emptyDescription: 'После бронирования здесь появятся все ваши объекты',
                toRealtyCatalog: 'Перейти в каталог',
            },
            button: {
                information: 'Информация',
                continue: 'К бронированию',
                awaiting_confirm: 'Ожидает подтверждения',
                mortgage: 'Заявка на ипотеку',
                pay: 'Оплатить всю стоимость'
            },
            tooManyRequests: {
                title: 'Завершите бронирование',
                description: 'У вас уже есть два незавершённых бронирования. Чтобы создать новое, завершите одно из текущих: оплатите его или дождитесь истечения времени бронирования.',
                toList: 'Перейти к списку бронирований'
            },
            alreadyBooking: {
                title: 'Вернуться к бронированию',
                description: 'Ваше бронирование находится в процессе оформления. Завершите его, чтобы гарантировать резервирование выбранного вами объекта.',
                cardTitle: 'Параметры бронирования',
                term: 'Срок',
                price: 'Цена бронирования',
                continue: 'Продолжить бронирование'
            }
        },
        summary: {
            title: 'Ваше бронирование',
            info: {
                booking: 'Бронирование',
                location: 'Локация',
                realty_info: 'Информация об объекте',
                documents: 'Документы',
                titles: {
                    booking_price: 'Стоимость бронирования',
                    term: 'Срок',
                },
            },
            download_offer: 'Договор оферты',
        },
        common: {
            time_is_up: {
                title: 'Время истекло',
                description: 'Отведенное время для бронирования истекло. Вы будете перенаправлены на страницу бронирования.'
            }
        },
        dialog_continue: {
            title: 'Вернуться к бронированию',
            description: 'Ваше бронирование находится в процессе оформления. Завершите его, чтобы гарантировать резервирование выбранного вами объекта.',
            parameters: 'Параметры бронирования',
            term: 'Срок',
            price: 'Цена бронирования',
            continue: 'Продолжить бронирование',
        }
    },
    admin: {
        registry: {
            title: 'Бронирования',
            headers: {
                client: 'Клиент',
                realty: 'Объект',
                tariff: 'Тариф',
                price: 'Стоимость',
                method: 'Способ',
                end_date: 'Срок до',
                status: 'Статус',
                contract: 'Договор',
            },
            dialog: {
                titles: {
                    contract: 'Номер договора',
                    buyer: 'Информация о клиенте',
                },
                placeholders: {
                    status: 'Выберите статус',
                    contract: 'Введите номер договора',
                },
                error: 'Введите номер договора',
                confirm: {
                    title: 'подтвердите действие',
                    text: {
                        approved: 'После подтверждения действия с клиентского счета будет списана сумма за бронирование.',
                        refusal: 'После подтверждения действия клиенту будет возвращена сумма за бронирование.',
                    },
                    return: 'Вернуться к бронированию',
                },
            }
        },
    }
};
