<script setup lang="ts">
import type { IBookingInfo } from '~/layers/booking/types/realty';

interface Props {
    bookingId: number;  // Идентификатор недвижимости
    info: IBookingInfo; // Информация для списка
}

const props = withDefaults(defineProps<Props>(), {});

const { 
    getInfoBlocks,
    getOfferGuid,
} = useBookingSummary();

const config = useRuntimeConfig();

const offerGuid = ref<string | null>(null);
const offerLink = computed<string>(() => {
    return `${config.public.fileApiBaseUrl}/files/booking/${offerGuid.value}/content`
});
const isLoading = ref<boolean>(false);

const infoBlocks = computed(() => {
    return getInfoBlocks(props.info) || [];
});

const loadGuidData = async () => {
    const result = await getOfferGuid(props.bookingId);

    if (!result.isError && result.data) {
        offerGuid.value = result.data;
    }
};

onMounted(async () => {
    await loadGuidData();
    isLoading.value = false;
});

</script>

<template>
    <div class="info-wrapper">
        <div v-if="infoBlocks?.length" class="info">
            <div 
                v-for="block in infoBlocks"
                :key="block.title"
                class="info-block"
            >
                <div class="info-block__title">{{ block.title }}</div>
                <div class="info-block__items">
                    <div 
                        v-for="item in block.items"
                        :key="item.title"
                        class="item"
                    >
                        <div class="item-title" v-html="item.title"></div>
                        <div class="item-value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="downloads">
            <v-skeleton-loader
                v-if="isLoading"
                color="neutral04"
                class="loader"
                type="text, button"
            />
            <template v-else>
                <div class="downloads-title">{{ $t('booking.client.summary.info.documents') }}</div>
                <a 
                    v-if="offerGuid"
                    class="link"
                    :href="offerLink"
                    target="_blank"
                >
                    <i class="ri-attachment-2"></i>
                    <div>{{ $t('booking.client.summary.download_offer') }}</div>
                </a>
                <div v-else>
                    -
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
.loader {
    width: 100%;
}

.info {
    &-block {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $color-neutral06;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__title {
            font-family: $ff-bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
        }

        &__items {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .item {
                width: calc(50% - 12px);

                @include responsive( 'mobile' ) {
                    width: 100%;
                }

                &-value {
                    margin-top: 12px;
                    font-family: $ff-bold;
                }
            }
        }
    }
}

.downloads {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-title {
        font-family: $ff-bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
    }

    .link {
        display: flex;
        align-items: center;
        gap: 12px;

        > i {
            color: $color-inverse;
            font-size: 24px;
        }

        > div {
            font-family: $ff-bold;
            font-size: 16px;

            @include responsive( 'mobile' ) {
                font-family: $ff-regular;
            }
        }
    }
}
</style>