<script setup lang="ts">
import type { IAcceptancesRealty } from "~/layers/settlement/types/realty";
import Button from '@frontend/ui-kit/src/components/Base/Button.vue';
import Chip from '@frontend/ui-kit/src/components/Base/Chip.vue';
import realtyHelper from '~/utils/realtyHelper';

interface Props {
    realty?: IAcceptancesRealty; // объект недвижимости.
}

const props = defineProps<Props>();

const { 
    realty = {
        realtyId: 0,
        realtyType: 0,
        address: '',
        square: 0,
        schemeUrl: '',
        acceptanceId: undefined,
        acceptanceStatus: undefined,
        acceptanceStatusDescription: '',
    } as IAcceptancesRealty,
} = props;

const { 
    isButtonShow,
    getButtonText,
    getButtonVariant,
    goToPage,
} = useSettlementList();

const { getAcceptanceStatusColor } = useAcceptanceStatus();
</script>

<template>
    <div class="apartment">
        <div class="apartment-img">
            <img 
                v-if="realty.schemeUrl" 
                :src="realty.schemeUrl" 
                alt="scheme"/>
            <div v-else class="no-image-scheme">
                <img 
                    src="/images/no-image.svg" 
                    alt="scheme"/>
            </div>
            
        </div>
        <div class="apartment-info">
            <div class="info-title">
                <span>{{ realtyHelper.getRealtyTypeText($t, realty.realtyType) }}</span>&nbsp;
                <span>{{ realty.square }}</span>&nbsp;
                <span v-html="$t('forms.shared.dimensions.meters2')"></span>
            </div>
            <div class="info-address">{{ realty.address }}</div>
            <div v-if="realty.acceptanceStatus" class="info-chip">
                <Chip
                    :color="getAcceptanceStatusColor(realty.acceptanceStatus)" 
                    :text="realty.acceptanceStatusDescription"
                />
            </div>
        </div>
        <div class="apartment-action">
            <template v-if="realty.acceptanceStatus">
                <Button 
                    v-if="isButtonShow(realty.acceptanceStatus)"
                    styleClass="apartment-action__btn"
                    :variant="getButtonVariant(realty.acceptanceStatus)"
                    @click="goToPage(realty.acceptanceStatus, realty.acceptanceId)"
                >
                    {{ getButtonText(realty.acceptanceStatus) }}
                </Button>
            </template>

        </div>
    </div>
</template>

<style scoped lang="scss">
.apartment {
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid $border-color;
    background: $main--contrast-bg-color;

    @include responsive( 'mobile' ) {
        margin-bottom: 24px;
        flex-wrap: wrap;
    }

    &:last-child {
        margin-bottom: 0;

        @include responsive( 'mobile' ) {
            margin-bottom: 0;
        }
    }

    .apartment-img {
        max-width: 118px;
        min-width: 118px;
        display: flex;
        align-items: center;

        @include responsive( 'mobile' ) {
            margin-top: 20px;
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            order: 1;
        }
        
        img {
            width: 100%;
        };
    }

    .no-image-scheme {
        display: flex;
        width: 118.426px;
        height: 92px;
        padding: 24px 37.426px 24px 37px;
        justify-content: center;
        align-items: center;

        border-radius: var(--Border-radius-rounded-xsm, 4px);
        background: var(--Color-Zink-100, #F4F4F5);

        @include responsive( 'mobile' ) {
            margin-top: 20px;
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            order: 1;
        }

        img {
            width: 44px;
            height: 44px;
            flex-shrink: 0;
        }
    }

    &-info {
        padding: 0 20px;
        flex-grow: 1;

        @include responsive( 'mobile' ) {
            order: 0;
            width: 100%;
            padding: 0;
        }
    }

    &-action {
        @include responsive( 'mobile' ) {
            margin-top: 16px;
            width: 100%;
            order: 2;
        }

        &__btn {
            width: 220px;
            border: 1px solid $color-neutral02;

            @include responsive( 'mobile' ) {
                width: 100%;
            }
        }
    }
}

.info {
    &-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 125%;
        letter-spacing: -0.16px;
        text-transform: uppercase;
    }

    &-address {
        margin-top: 12px;
        font-size: 14px;
        line-height: 125%; 
        letter-spacing: -0.14px;
        color: $secondary-text-color;
    }

    &-chip {
        margin-top: 12px;
    }
}
</style>