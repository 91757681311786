import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type { AcceptanceStatus } from '~/layers/settlement/types/realty';
import type { RealtyType } from '~/types/common/realty';
import { RealtyTypes } from '~/constants/realty';
import { AcceptanceStatuses, AcceptanceUIStatuses } from '~/layers/settlement/constants/statuses';
import type { IAcceptancesFilterRealtyNumber } from "~/layers/settlement/types/acceptancesTable";
import realtyHelper from '~/utils/realtyHelper';
import type { INumberKeyValueData } from "@frontend/ui-kit/src/types/keyValue"; 

export const useAcceptancesFilters = () => {
    const { t } = useI18n();

    const { get } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();

    /**
    * Возвращает список номеров объектов недвижимости
    * @param filter Фильтр.
    * @param take Количество записей, которое необъодимо вернуть.
    * @param skip Количество записей, которое необходимо пропустить.
    */
    const getRealtyNumberFilter = async (number: string, take: number, skip: number) : Promise<Result<IAcceptancesFilterRealtyNumber>> => {
        return await tryAction(async () => {
            const url = `${config.public.realtyApiBaseUrl}/Realty/numbers/manager/list?number=${number}&take=${take}&skip=${skip}`;
            return await get<IAcceptancesFilterRealtyNumber>(url);
        });
    };

    /** получает все типы обектов в виде массива с ключами и значениями */
    const getRealtyTypes = (): INumberKeyValueData[] => {
        const types: INumberKeyValueData[] = [];


        Object.values(RealtyTypes).forEach((type, index) => {
            if(type == RealtyTypes.Undefined) {
                return;
            }

            types.push({
                type: index,
                name: realtyHelper.getRealtyTypeText(t, type as RealtyType),
            });
        });
    
        return types;
    };

    /**
     * Преобращует статус-фильтр в стаутс для Api.
     * @param status Статус.
     * @returns Статус для API.
     */
    const getAcceptanceStatusApiFilter = (status: string | null) : AcceptanceStatus[] =>  {
        if(status == AcceptanceUIStatuses.New) {
            return [AcceptanceStatuses.Draft];
        } else if(status == AcceptanceUIStatuses.Invited) {
            return [AcceptanceStatuses.Participant, AcceptanceStatuses.CheckPersonalData, AcceptanceStatuses.ChooseDate];
        } else if(status == AcceptanceUIStatuses.Appointmented) {
            return [AcceptanceStatuses.NeedPrepareDocuments];
        } else if(status == AcceptanceUIStatuses.Prepared) {
            return [AcceptanceStatuses.Meeting];
        } else if(status == AcceptanceUIStatuses.SuccessResult) {
            return [AcceptanceStatuses.SuccessResult];
        } else if(status == AcceptanceUIStatuses.ResultWithRemarks) {
            return [AcceptanceStatuses.ResultWithRemarks];
        } else if(status == AcceptanceUIStatuses.FailResult) {
            return [AcceptanceStatuses.FailResult];
        } if(status == AcceptanceUIStatuses.Cancelled) {
            return [AcceptanceStatuses.Cancelled];
        } else {
            return [];
        }
    }

    return {
        getRealtyNumberFilter,
        getRealtyTypes,
        getAcceptanceStatusApiFilter
    };
};