import type { ErrorData, ApiResponse } from "@frontend/ui-kit/src/types/error";
import { Result } from "@frontend/ui-kit/src/types/result";
import { useNotificationStore } from '~/stores/alert/useNotificationStore';
import { StatusCodes } from "http-status-codes";

/** Безопасные методы. */
export const useSafe = () => {
    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const tryAction = async <Type>(
        action: () => Promise<Type>,
        errorProcess: ((e: ErrorData) => void) | undefined = undefined)
        : Promise<Result<Type>> => {
        return await tryActionInner(action, errorProcess ? errorProcess : showError);
    }

    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const trySilentAction = async <Type>(action: () => Promise<Type>) : Promise<Result<Type>> => {
        return await tryActionInner(action);
    }

    /**
     * Выполняет действие безопасно.
     * @param action Действие.
     * @param errorProcess Обработка ошибки.
     * @returns Результат действия.
     */
    const tryActionInner = async <Type>(
        action: () => Promise<Type>,
        errorProcess: ((e: ErrorData) => void) | undefined = undefined)
        : Promise<Result<Type>> => {
        try {
            const data = await action();
            return Result.success<Type>(data);
        } catch (e) {
            const error = e as ApiResponse;
            if(errorProcess){
                errorProcess(error);
            }

            return Result.error<Type>(error.statusCode ?? StatusCodes.INTERNAL_SERVER_ERROR, error.message);
        }
    }

    /** Показывает сообщение об ошибке. */
    const showError = (e: ErrorData) : void => {
        const { $i18n } = useNuxtApp();

        // Ошибки авторизации не показываем, должен быть только редирект на логин
        const apiError = e as ApiResponse;
        if(apiError && apiError.statusCode == StatusCodes.UNAUTHORIZED) {
            return;
        }
        const store = useNotificationStore();
        store.showErrorText($i18n.t, e.message);
    }

    return {
        tryAction,
        trySilentAction
    }
}
