import type { Result } from "@frontend/ui-kit/src/types/result";
import type { IStepsRealty, IRealtyFullInfo, IBookingSummary, IBookingInfo, IBookingsRealty } from "~/layers/booking/types/realty";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import realtyHelper from '~/utils/realtyHelper';
import priceHelper from '@frontend/ui-kit/src/utils/priceHelper';
import dateTimeHelper from '@frontend/ui-kit/src/utils/dateTimeHelper';
import { RealtyTypes } from "~/constants/realty";
import { BookingStatuses } from "~/layers/booking/constants/statuses";
import { DocumentTypes } from "~/layers/booking/constants/documentTypes";

export const useBookingSummary = () => {
    const { t } = useI18n();
    const { get } = useApi();
    const { tryAction } = useSafe();

    const config = useRuntimeConfig();

    /**
    * Функция для получения информации о бронировании
    * @param bookingId идентификатор бронирования
    * @returns объект полей с информацией
    */ 
    const getBookingData = async (bookingId: number) : Promise<Result<IBookingSummary>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}`;
            return await get<IBookingSummary>(url);
        });
    };

    /**
    * Функция для получения информации о бронировании
    * @param bookingId идентификатор бронирования
    * @returns объект полей с информацией
    */ 
    const getOfferGuid = async (bookingId: number) : Promise<Result<string>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}/document/type/${DocumentTypes.Offer}`;
            return await get<string>(url);
        });
    };

    /**
    * Возвращает строку заголовка для объекта
    * @param realty объект (realty)
    * @returns Заголовок объекта в виде строки
    */
    const createTitle = (realty: IBookingsRealty) => {
        if (!realty) {
            return '';
        }

        const realtyType = realty.realtyTypeName
            ? realty.realtyTypeName 
            : realtyHelper.getRealtyTypeText(t, realty.realtyType);
        const realtySquare = realty.square ? `${realty.square}${t('forms.shared.dimensions.meters2')}` : '';

        return `${realtyType} ${realtySquare}`;
    }
    
    /**
    * Возвращает строку общей информации об объекте
    * @param realty объект (realty)
    * @returns Информация об объекте в виде строки
    */
    const createMainlInfo = (realty: IStepsRealty) => {
        if (!realty) {
            return '';
        }

        const project = realty.project ? `${realty.project} &bull;` : '';
        const address = realty.address ? `${realty.address} &bull;` : '';
        const realtyName = realty.realtyTypeName;
        const floor = realty.floorNumber ? `${t('booking.common.info_titles.floor')} ${realty.floorNumber}.` : '';
        const number = realty.number ? `${realtyName} ${realty.number}` : '';
        const circle = realty.floorNumber || realty.number ? '&bull;' : '';
        const realtyType = `${floor} ${number} ${circle}`;
        const isTypeFinish = realty.isTypeFinish;
    
        return `${project} ${address} ${realtyType} ${isTypeFinish}`;
    }
    
    /**
    * Возвращает массив подробной информации об объекте
    * @param realty объект (realty)
    * @returns Массив информации об объекте: заголовок-значение
    */
    const createFullInfo  = (realty: IStepsRealty) :IRealtyFullInfo[] => {
        if (!realty) {
            return [];
        }

        return [
            {
                title: `${t('booking.common.info_titles.location')}: `,
                text: realty.address ? realty.address : '—',
            },
            {
                title: `${t('booking.common.info_titles.floor')}: `,
                text: realty.floorNumber && realty.floorsCount ? `${realty.floorNumber}/${realty.floorsCount}` : '—',
            },
            {
                title: `${t('booking.common.info_titles.finishing')}: `,
                text: realty.isTypeFinish ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no'),
            },
        ]
    }
    
    /**
    * Возвращает массив информации об объекте в контентной части аккордеона
    * @param realty объект (realty)
    * @returns Массив информации об объекте: заголовок-значение
    */
    const createContentInfo  = (realty: IStepsRealty) :IRealtyFullInfo[] => {
        if (!realty) {
            return [];
        }

        const bookingEnd = dateTimeHelper.toDate(realty.bookingEnd);
        return [
            {
                title: `${t('booking.common.info_titles.realty_price')}: `,
                text: realty.realtyPrice ? priceHelper.toStringWithRuble(realty.realtyPrice) : '—',
            },
            {
                title: `${t('booking.common.info_titles.purchase_condition')}: `,
                text: realty.purchaseCondition ? realty.purchaseCondition : '—',
            },
            {
                title: `${t('booking.common.info_titles.booking_price')}: `,
                text: realty.bookingPrice ? priceHelper.toStringWithRuble(realty.bookingPrice) : '—',
            },
            {
                title: `${t('booking.common.info_titles.booking_period')}: `,
                text: realty.bookingEnd ? `${t('forms.shared.prepositions.until')} ${dateTimeHelper.toDateString(bookingEnd)}` : '—',
            },
        ];
    };

    /** Поля для объекта бронирования по умолчанию */
    const defaultBooking:IBookingSummary = {
        id: 0,
        bookingEnd: '',
        bookingPrice: 0,
        bookingStatus: BookingStatuses.Undefined,
        realtyInfo: {
            realtyType: RealtyTypes.Undefined,
            address: '',
            square: 0,
            schemeUrl: '',
            number: '',
            realtyTypeName: '',
            floorNumber: 0,
            floorsCount: 0,
            realtyPrice: 0,
            sectionNumber: 0,
            hasKitchen: false,
            hasFurniture: false,
            isTypeFinish: false,
            buildingBlock: '',
            project: '',
            roomCount: 0,
        },
    }

    /**
    * Создает объект для информации о бронировании из объекта бронирования
    * @param bookingData объект информации о бронированим
    * @returns объект полей с информацией
    */ 
    const createBookingInfo = (bookingData: IBookingSummary) : IBookingInfo => {
        const bookingInfo: IBookingInfo = {
            bookingId: bookingData.id,
            bookingPrice: bookingData.bookingPrice,
            bookingEnd: bookingData.bookingEnd || '',
            address: bookingData.realtyInfo.address,
            square: bookingData.realtyInfo.square,
            number: bookingData.realtyInfo.number,
            floorNumber: bookingData.realtyInfo.floorNumber,
            floorsCount: bookingData.realtyInfo.floorsCount,
            sectionNumber: bookingData.realtyInfo.sectionNumber,
            hasKitchen: bookingData.realtyInfo.hasKitchen,
            hasFurniture: bookingData.realtyInfo.hasFurniture,
            isTypeFinish: bookingData.realtyInfo.isTypeFinish,
            buildingBlock: bookingData.realtyInfo.buildingBlock,
            project: bookingData.realtyInfo.project,
            roomCount: bookingData.realtyInfo.roomCount,
        }

        return bookingInfo;
    };

    /**
    * Возвращает массив информации об объекте для детальной страницы бронирования
    * @param booking объект с информацией о бронировании
    * @returns Массив информации об объекте: массивы - заголовок-значение
    */
    const getInfoBlocks  = (info: IBookingInfo) => {
        if (!info) {
            return [];
        }

        const {
            bookingPrice,
            bookingEnd,
            project,
            address,
            buildingBlock,
            sectionNumber,
            number,
            square,
            floorNumber,
            floorsCount,
            roomCount,
            isTypeFinish,
            hasKitchen,
            hasFurniture,
        } = info;

        // создаем строки для отображения
        const bookingPriceString = bookingPrice ? priceHelper.toStringWithRuble(bookingPrice) : '—';

        // дата бронирования
        const date = bookingEnd ? dateTimeHelper.toDate(bookingEnd) : null;
        const dateString = bookingEnd 
            ? `${t('forms.shared.prepositions.until')} ${dateTimeHelper.toDateString(date)}` 
            : '—';
        // проект+адрес
        const projectString = project ? `${project},` : '';
        const addressString = address ? address : '—';
        const fullAddressString = `${projectString} ${addressString}`;
        // строение+секция
        const buildingString = buildingBlock ? buildingBlock : null;
        const sectionString = sectionNumber ? `(${t('realty.section')} ${sectionNumber})` : '';
        const buildingFullString = buildingString ? `${buildingString} ${sectionString}` : '—';
        // номер объекта
        const flatString = number ? `№ ${number}` : '—';
        // площадь 
        const squareString = square ? square : '—';
        // этаж/всего этажей
        const floorNumberString = floorNumber ? floorNumber : null;
        const floorsCountString = floorsCount 
            ? `${t('forms.shared.prepositions.outOf')} ${floorsCount}`
            : null;
        const floorString = floorNumberString ? `${floorNumberString} ${floorsCountString}`  : '—';
        // отделка, кухня, мебель
        const isTypeFinishName = isTypeFinish ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no');
        const kitchenString = hasKitchen ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no');
        const furnitureString = hasFurniture ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no');

        // блок 1: бронирование
        const bookingSection = {
            title: t('booking.client.summary.info.booking'),
            items: [
                {
                    title: t('booking.client.summary.info.titles.booking_price'),
                    value: bookingPriceString,
                },
                {
                    title: t('booking.client.summary.info.titles.term'),
                    value: dateString,
                },
            ],
        };

        // блок 2: адрес
        const locationSection = {
            title: t('booking.client.summary.info.location'),
            items: [
                {
                    title: t('realty.address'),
                    value: fullAddressString,
                },
                {
                    title: t('realty.building'),
                    value: buildingFullString,
                },
                {
                    title: t('realty.apartment'),
                    value: flatString,
                },
            ],
        };

        // блок 3: информация об объекте
        const infoSection = {
            title: t('booking.client.summary.info.realty_info'),
            items: [
                {
                    title: t('realty.square_m2'),
                    value: squareString,
                },
                {
                    title: t('realty.floor'),
                    value: floorString,
                },
                {
                    title: t('realty.finishing'),
                    value: isTypeFinishName,
                },
                {
                    title: t('realty.kitchen'),
                    value: kitchenString,
                },
                {
                    title: t('realty.furniture'),
                    value: furnitureString,
                },
            ],
        };
        
        // если у объекта есть количество комнат, то добавляем пункт
        if (roomCount) {
            const roomsBlock = {
                title: t('realty.room'),
                value: roomCount,
            }
            
            infoSection.items.splice(2, 0, roomsBlock);
        }

        return [bookingSection, locationSection, infoSection];
    }
    
    return {
        getBookingData,
        createTitle,
        createMainlInfo,
        createFullInfo,
        createContentInfo,
        defaultBooking,
        getInfoBlocks,
        createBookingInfo,
        getOfferGuid,
    };
};