<script lang="ts" setup>
import Notification from "@frontend/ui-kit/src/components/Base/Notification.vue";
import Footer from "~/components/shared/Common/Footer.vue";
import Header from "~/layers/booking/components/shared/common/Header.vue";
import { useNotificationStore } from "~/stores/alert/useNotificationStore";

const store = useNotificationStore();

defineProps({
    isError: { type: Boolean, default: false },
});

</script>

<template>
    <v-app class="app">
        <Header />
        <v-layout class="layout">
            <div v-if="!isError" class="main">
                <NuxtPage />
                <Footer />
            </div>
            <div v-else class="main-container">
                <slot />
            </div>
            <Notification :messages="store.messages"/>
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    background-color: $main-bg-color;

    :deep(.v-application__wrap) {
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;

    @include responsive("mobile") {
        flex-direction: column;
    }
}

.main {
    display: flex;
    padding: 24px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 32px;
    flex: 1 0 0;
}

.main-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;

    border-radius: 32px;
    background: $main-card-color;
    box-shadow: 0px 10px 40px -20px rgba(0, 0, 0, 0.1);

    @include responsive("mobile") {
        padding: 32px 0;
    }

    @include responsive("desktop") {
        padding: 24px 32px;
        gap: 24px;
    }
    @include responsive("desktop-small") {
        padding: 40px;
    }
    @include responsive("desktop-medium") {
        padding: 40px 80px;
    }
}
</style>
